const renderurl = process.env.RENDER_URL;
const netlifyurl = process.env.FRONT_URL;

window.ondblclick=Background_change_color_dbl;
var bg_switch = true;

function Background_change_color_dbl ()
{
  if (bg_switch){
    document.body.style.backgroundColor='#008083';
  }
  else{
    document.body.style.backgroundColor='#ffffff';
  }
  bg_switch = !bg_switch;
  
}

var url = document.location.href;
const user = url.substring(url.lastIndexOf("/") + 1, url.length);
//console.log(user);
if (user == "") {
  window.location.href = netlifyurl+"?sn=getstarted";
} 

//get the sn attribute from the url
const urlParams = new URLSearchParams(window.location.search);
const sn = urlParams.get("sn");
//console.log(sn);

if (sn) {
//searching for a user
  //window.location.href = netlifyurl + checkuser;
  fetch(renderurl+"api/users/" + sn)
    .then((response) => response.json())
    .then(function (data) {
      if (!data.username) {
        document.getElementById("profile-title").innerHTML =
          "&#128566 Buddy Info"; 
        document.getElementById("quilleditor").innerHTML =
          "<p>That screename does not exist, try another one!</p>".valueOf(); 
        
      } else {
        document.getElementById("profile-title").innerHTML =
          "&#128513; Buddy Info: " + data.username;
        if (data.profile) {
          const value = data.profile.valueOf();
          const delta = quill.clipboard.convert(value);
          quill.setContents(delta, "silent");
        }
      }
    }); 
  }
  

//searching for a user
document.getElementById("test-btn").addEventListener("click", function () {
  const checkuser = document.getElementById("username-input").value;
  //window.location.href = netlifyurl + checkuser;
  fetch(renderurl+"api/users/" + checkuser)
    .then((response) => response.json())
    .then(function (data) {
      if (!data.username) {
        /* document.getElementById("profile-title").innerHTML =
          "&#65039; Buddy Info"; */
        document.getElementById("profile-title").innerHTML =
          "&#128566 That screename does not exist, try another one!".valueOf(); 
        
      } else {
        document.getElementById("profile-title").innerHTML =
          "&#128513; Buddy Info: " + data.username;
        if (data.profile) {
          const value = data.profile.valueOf();
          const delta = quill.clipboard.convert(value);
          quill.setContents(delta, "silent");
        }
      }
    }); 
  });

//if a user is logged in, adjust the nav accordingly
const token = localStorage.getItem("token");
if (!token) {
  //addclass to edit profile button
 /*  document.getElementById("edit-profile-btn").classList.add("hidden");
  document.getElementById("logout-btn").classList.remove("save-btn");
  document.getElementById("logout-btn").classList.add("hidden"); */
  document.getElementById("edit-profile-btn").style.display = "none";
  document.getElementById("logout-btn").style.display = "none"; 
}
if (token) {
  /* document.getElementById("register-btn").classList.add("hidden");
  document.getElementById("login-btn").classList.add("hidden"); */
  document.getElementById("register-btn").style.display = "none";
  document.getElementById("login-btn").style.display = "none"; 
}

//logout function
document.getElementById("logout-btn").addEventListener("click", function () {
  localStorage.removeItem("token");
  //reload
  //location.reload();
  window.location.href = netlifyurl+"?sn=getstarted";
});

var quill = new Quill("#quilleditor", {
  modules: {
    toolbar: false,
  },
  readOnly: true,
  theme: "snow",
});
